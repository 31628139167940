<script lang="ts" setup>
const { open } = useNlcModals();
const { data } = useAppInitData();

const BG_LEVEL = 'url("/nuxt-img/seasons/halloween/tower-level.png") no-repeat center center / cover';
const progressValue = computed(() => `${data.value?.season?.totalProgressPercents}%`);
const progressLevel = computed(() => data.value?.season?.currentLevel);

const handleClick = () => {
	open("LazyOModalSpookySeasonTower");
};
</script>

<template>
	<div @click="handleClick()">
		<div class="icon">
			<NuxtImg
				src="/nuxt-img/seasons/halloween/congratulations-pumpkin-fifth.png"
				width="657"
				height="547"
				format="webp"
				loading="lazy"
				alt="pumpkin"
			/>
		</div>

		<div class="content">
			<AText class="color-neutral-100 title" :size="16" :modifiers="['bold']" as="div"> Spooky Season </AText>
			<div class="level-progress">
				<AText class="level" :size="17" :modifiers="['center', 'bold']" as="div">
					{{ progressLevel }}
				</AText>
				<div class="progress-active" />
			</div>
		</div>
		<AButton variant="primary" class="btn" size="s">
			<AText :size="12" :modifiers="['uppercase']">Let`s go</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: auto;
		object-fit: contain;
	}
}
.level-progress {
	position: relative;
	width: calc(100% - 8px);
	height: 23px;
	margin: 9px 0 0 9px;
	background: #010101;
	border: 2px solid #898989;
	border-radius: 50px;
	.level {
		width: 32px;
		height: 32px;
		line-height: 32px;
		background: v-bind(BG_LEVEL);
		position: absolute;
		top: -8px;
		left: -13px;
		z-index: 1;
	}
	.progress {
		position: absolute;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		text-shadow: 1.15px 1.15px 0px var(--custom-rgb-12);
		.nuxt-icon {
			font-size: 20px;
			margin-right: gutter(0.375);
		}
		&-active {
			position: absolute;
			top: 2px;
			left: 0;
			width: v-bind(progressValue);
			height: 15px;
			background: #f0c133;
			border-radius: 50px;
			z-index: 0;
		}
	}
}
</style>
